<template>
  <div>
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'View Organisations', route: 'Admin_Organisations' },
        { name: 'Manage Organisations', route: 'Admin_Organisations' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <div class="col-6">
          <div class="error" v-for="error in errors" :key="error">
            {{ error }}
          </div>
          <div class="success" v-for="message in messages" :key="message">
            {{ message }}
          </div>
          <form class="form" v-on:submit.prevent="UpdateOrganisation">
            <label for="form.name">Organisation Name</label>
            <input type="text" class="input" v-model="form.name" />
            <label for="form.address">Address</label>
            <textarea
              class="input left"
              v-model="form.address"
              rows="4"
              required
            ></textarea>
            <label for="form.postcode">Postcode</label>
            <input type="text" class="input" v-model="form.postcode" required />
            <label for="form.contact_email">Contact Email</label>
            <input
              type="email"
              class="input"
              v-model="form.contact_email"
              required
            />
            <label for="form.contact_telephone">Contact Telephone</label>
            <input
              type="tel"
              class="input"
              v-model="form.contact_telephone"
              required
            />
            <br />
            <br />
            <input type="submit" class="btn btn-green" value="Save Changes" />
          </form>
        </div>
        <div class="col-6">
          <table class="table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Email</td>
                <td colspan="2">Role</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="1">
                  <label for="adduser.email">Email Address</label>
                  <input
                    id="adduser.email"
                    type="email"
                    class="input"
                    v-model="adduser.email"
                    placeholder="example@example.com"
                  />
                </td>
                <td>
                  <label for="adduser.role">Role</label>
                  <select
                    class="input"
                    v-model="adduser.role"
                    id="adduser.role"
                  >
                    <option value="1">Sales Rep</option>
                    <option value="2">Member</option>
                    <option value="3">Manager</option>
                  </select>
                </td>
                <td colspan="2">
                  <button
                    class="btn btn-green"
                    v-on:click="addUserToOrganisation"
                  >
                    Invite User <i class="icon icon-pen"></i>
                  </button>
                </td>
              </tr>
              <tr v-for="(user, index) in users" :key="user">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ roleTypes(user.role) }}</td>
                <td>
                  <div
                    class="btn btn-orange"
                    v-on:click="RemoveUserFromOrganisation(user.user_id, index)"
                  >
                    <span class="icon icon-delete"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

import Navigation from "@/components/Navigation.vue";

export default {
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    return {
      messages: "",
      errors: "",
      form: {},
      users: [],
      adduser: {
        email: "",
        role: 1,
      },
    };
  },
  components: {
    Navigation,
  },
  methods: {
    GetOrganisation() {
      this.store
        .dispatch("Admin/GetOrganisationInfo", this.$route.params.id)
        .then((result) => {
          console.log(result);
          this.form = result.data;
          this.users = result.users;
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
    UpdateOrganisation() {
      this.form.id = this.$route.params.id;
      this.store
        .dispatch("Admin/UpdateOrganisationInfo", this.form)
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
          console.log(err);
        });
    },
    roleTypes(int) {
      switch (int) {
        case 1:
          return "Sales Rep";
        case 2:
          return "Member";
        case 3:
          return "Manager";
        default:
          return "Please Select a Role";
      }
    },
    addUserToOrganisation() {
      if (this.adduser.email !== "" && this.adduser.role !== "") {
        this.store
          .dispatch("Admin/AddUserToOrganisation", {
            id: this.$route.params.id,
            email: this.adduser.email,
            role: this.adduser.role,
          })
          .then((result) => {
            this.messages = result.messages;
            this.errors = "";
          })
          .catch((err) => {
            this.errors = err.messages;
            this.messages = "";
            console.log(err);
          });
      }
    },
    RemoveUserFromOrganisation(user, index) {
      this.store
        .dispatch("Admin/RemoveUserFromOrganisation", {
          id: this.$route.params.id,
          user_id: user,
        })
        .then((result) => {
          this.messages = result.messages;
          this.errors = "";
          this.users.splice(index, 1);
        })
        .catch((err) => {
          this.errors = err.messages;
          this.messages = "";
        });
    },
  },
  mounted() {
    this.GetOrganisation();
  },
};
</script>

<style>
</style>